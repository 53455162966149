import React, { forwardRef } from 'react'
import { Box } from '@mantine/core'
import classes from './print-only.module.css'

interface PrintOnlyProps {
  children: React.ReactNode
}

export const PrintOnly = forwardRef<HTMLDivElement, PrintOnlyProps>(({ children }, ref) => (
  // Font family has backup to fix issue with printing in Chrome
  <Box ref={ref} className={classes.printable}>
    {children}
  </Box>
))
