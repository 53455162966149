import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Stack, Tabs } from '@mantine/core'
import { PageContainer } from '@/components/shared/app-shell/page-container/page-container'
import { StationHeader } from '@/components/stations/header/station-header'
import { addTitleSuffix } from '@/constants/page-titles'
import { StationDetailsPage } from '@/pages/stations/details/station-details.page'
import { StationWoolDataPage } from '@/pages/stations/wool-data/station-wool-data.page'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'
import { GetStationHeaderGql } from '@/graphql/queries/get-station-header.graphql'
import { IconAlert, AlertLevel } from '@/components/shared/icon-alert'
import { StationContext } from '@/pages/stations/station-context'

export function StationPage() {
  const navigate = useNavigate()
  const { stationRecordNumber, stationTabValue } = useParams()

  const { data, isLoading, isError } = useAuthenticatedGraphQuery({
    queryKey: ['stationData', stationRecordNumber],
    gql: GetStationHeaderGql,
    queryParams: { recordNumber: stationRecordNumber },
    alwaysThrowOnError: true,
  })

  // If root station route, redirect to details panel by default
  useEffect(() => {
    if (data?.stationHeader?.recordNumber && stationTabValue === undefined) {
      navigate(`/stations/${data?.stationHeader?.recordNumber}/details`)
    }
  }, [data?.stationHeader?.recordNumber, stationTabValue])

  return (
    <StationContext.Provider value={data?.stationHeader ?? null}>
      <PageContainer title={addTitleSuffix(data?.stationHeader?.brand || '')}>
        {isError && (
          <IconAlert
            level={AlertLevel.ERROR}
            title="Error"
            message="Failed to fetch station data"
          />
        )}
        {data?.stationHeader === null ? (
          <IconAlert
            level={AlertLevel.ERROR}
            title="Station not found"
            message={`No station found with record number ${stationRecordNumber}`}
          />
        ) : (
          <Stack>
            <StationHeader
              brand={data?.stationHeader?.brand || ''}
              recordNumber={data?.stationHeader?.recordNumber}
              onHold={data?.stationHeader?.isOnHold || false}
              isLoading={isLoading || !data}
              stationsInGroup={data?.stationHeader?.stationsInGroup || []}
            />
            {data?.stationHeader && (
              <Tabs
                keepMounted={false}
                defaultValue="details"
                value={stationTabValue}
                onChange={(value) =>
                  navigate(`/stations/${data?.stationHeader?.recordNumber}/${value}`)
                }
              >
                <Tabs.List mb="md">
                  <Tabs.Tab value="details">Details</Tabs.Tab>
                  <Tabs.Tab value="speci" disabled>
                    Speci
                  </Tabs.Tab>
                  <Tabs.Tab value="offers" disabled>
                    Offers
                  </Tabs.Tab>
                  <Tabs.Tab value="wool-data">Wool data</Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel value="details">
                  <StationDetailsPage />
                </Tabs.Panel>
                <Tabs.Panel value="wool-data">
                  <StationWoolDataPage />
                </Tabs.Panel>
              </Tabs>
            )}
          </Stack>
        )}
      </PageContainer>
    </StationContext.Provider>
  )
}
