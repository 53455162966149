import { gql } from 'graphql-request'

export const GetWoolContractsGql = gql`
  query listWoolContracts($stationRecordNumber: String!, $season: Int) {
    listWoolContracts(request: { stationRecordNumber: $stationRecordNumber, season: $season }) {
      sumOfBalesContracted
      results {
        averagePriceCLN
        balesContracted
        colourDescription
        contractDeliveryDate
        contractName
        greasyLengthMax
        greasyLengthMin
        micronMax
        micronMin
        purchaseAccountRecordNumber
        styleDescription
        targetGreasyLength
        targetMicron
        targetMinimumYield
        targetSampleStrength
        targetVegetableMatter
        contractedLots {
          reference
          bales
          lineNumber
          netKg
          micron
          result
          priceClean
          redaType
          paymentDate
        }
        totalBalesAccepted
      }
    }
  }
`
