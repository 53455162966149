import { gql } from 'graphql-request'

export const GetUnsoldLotsGql = gql`
  query listUnsoldLots($testedLots: Boolean, $typedLots: Boolean) {
    listUnsoldLots(request: { testedLots: $testedLots, typedLots: $typedLots }) {
      results {
        saleNumber
        season
        stationRecordNumber
        stationBrand
        internalReferenceCode
        interlotWarning
        code
        totalBales
        mainBaleHeaderPurchaseDescription
        typeCurrent
        internalComment
        coreTestMicron
        coreTestVegetableMatterBase
        coreTestYield
        coreTestColourYZ
        coreTestStapleLengthGreasy
        coreTestStapleStrength
        purchaseAccountName
        qualityScheme2
        qualitySchemeWarning
      }
    }
  }
`
