import { gql } from 'graphql-request'

export const StationSearchGql = gql`
  query stationSearch($searchTerm: String!, $limit: Int) {
    stationSearch(searchTerm: $searchTerm, limit: $limit) {
      tradingName
      brand
      isOnHold
      recordNumber
      agentOrBuyer
    }
  }
`
