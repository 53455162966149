import { useState } from 'react'
import { Group, Stack, Switch, Title } from '@mantine/core'
import { UnsoldLotsTable } from '@/components/stations/offers/unsold-lots-table/unsold-lots-table'
import { useAuthenticatedGraphQuery } from '@/hooks/use-authenticated-query'
import { GetUnsoldLotsGql } from '@/graphql/queries/offers/get-unsold-lots.graphql'

export function UnsoldLots() {
  const [viewTypedLots, setViewTypedLots] = useState(false)
  const [viewTestedLots, setViewTestedLots] = useState(false)

  // Get the unsold lots data
  const { data, isLoading, isError } = useAuthenticatedGraphQuery({
    queryKey: ['unsoldLotsData', viewTypedLots, viewTestedLots],
    gql: GetUnsoldLotsGql,
    queryParams: {
      testedLots: viewTestedLots,
      typedLots: viewTypedLots,
    },
  })

  return (
    <Stack>
      <Group>
        <Title order={2} data-testid="offers-unsold-lots" mr="auto">
          Unsold Lots
        </Title>
        <Switch
          label="Typed lots"
          checked={viewTypedLots}
          onChange={(event) => setViewTypedLots(event.currentTarget.checked)}
        />
        <Switch
          label="Tested lots"
          checked={viewTestedLots}
          onChange={(event) => setViewTestedLots(event.currentTarget.checked)}
        />
      </Group>
      <UnsoldLotsTable
        data={data?.listUnsoldLots?.results ?? []}
        isLoading={isLoading}
        isError={isError}
      />
    </Stack>
  )
}
