import { gql } from 'graphql-request'

export const GetStationCRMDetailsGql = gql`
  query crmCompany($recordNumber: String!) {
    crmCompany(stationRecordNumber: $recordNumber) {
      tradingName
      physicalAddressStreetAndSuburb
      physicalAddressTown
      physicalAddressCityRegionState
      physicalAddressCountry
      physicalAddressRapidNumber
      nzmAreaManagerFirstName
      nzmAreaManagerLastName
      accountManagerNameFirstName
      accountManagerNameLastName
      crmLink
    }
  }
`
