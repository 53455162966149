import { useMemo } from 'react'
import { MantineReactTable, MRT_ColumnDef, useMantineReactTable } from 'mantine-react-table'
import { Anchor } from '@mantine/core'
import { Link } from 'react-router-dom'
import { hubTableDefaultProps } from '@/components/shared/table/table'
import { NoDataMessage } from '@/components/shared/table/table-no-data-message'
import { SearchStationResult } from '@/loom-gql/graphql'
import { AlertLevel, IconAlert } from '@/components/shared/icon-alert'
import { PillAlert } from '@/components/shared/pill-alert'

interface StationSearchTableProps {
  data?: SearchStationResult[]
  noDataMessage?: string
  isFetching: boolean
  isLoading: boolean
  isError: boolean
}

export function StationsSearchTable({
  data = [],
  noDataMessage = 'No stations found',
  isFetching,
  isLoading,
  isError,
}: StationSearchTableProps) {
  const columns = useMemo<MRT_ColumnDef<SearchStationResult>[]>(
    () => [
      {
        accessorKey: 'recordNumber',
        header: 'Station ID',
        id: 'stationDetailLink',
        Cell: ({ row }) => (
          <Anchor component={Link} to={`/stations/${row.original.recordNumber}/details`} size="sm">
            {row.original.recordNumber}
          </Anchor>
        ),
      },
      {
        accessorKey: 'brand',
        header: 'Brand',
      },
      {
        accessorKey: 'tradingName',
        header: 'Trading Name',
      },
      {
        accessorKey: 'agentOrBuyer',
        header: 'Area Manager',
      },
      {
        accessorKey: 'isOnHold',
        header: 'Status',
        Cell: ({ cell }) => (cell.getValue() ? <PillAlert text="On Hold" hideIcon /> : null),
      },
      {
        accessorKey: 'recordNumber',
        header: '',
        id: 'woolDataLink',
        Cell: ({ row }) => (
          <Anchor
            component={Link}
            to={`/stations/${row.original.recordNumber}/wool-data`}
            size="sm"
          >
            Wool Data
          </Anchor>
        ),
      },
    ],
    []
  )

  const table = useMantineReactTable({
    ...hubTableDefaultProps,
    columns,
    data,
    initialState: {
      ...hubTableDefaultProps.initialState,
      // Ensures the link columns are always displayed
      columnPinning: { left: ['stationDetailLink'], right: ['woolDataLink'] },
    },
    state: {
      showSkeletons: isLoading,
      showProgressBars: isFetching,
      showAlertBanner: isError,
    },
    enableTopToolbar: false,
    enableColumnPinning: true,
    renderEmptyRowsFallback: () => <NoDataMessage noDataMessage={noDataMessage} />,
  })

  return isError ? (
    <IconAlert title="Error retrieving search results" level={AlertLevel.ERROR} />
  ) : (
    <MantineReactTable table={table} />
  )
}
