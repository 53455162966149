import { useEffect } from 'react'
import { Stack, Tabs } from '@mantine/core'
import { useNavigate, useParams } from 'react-router-dom'
import { BaleDetails } from '@/pages/stations/wool-data/bale-details/bale-details.page'
import { CreditorHistory } from '@/pages/stations/wool-data/creditor-history/creditor-history.page'
import { LotDetails } from '@/pages/stations/wool-data/lot-details/lot-details.page'
import { ReceivalHistory } from '@/pages/stations/wool-data/receival-history/receival-history.page'
import { WoolContracts } from '@/pages/stations/wool-data/wool-contracts/wool-contracts.page'

export function StationWoolDataPage() {
  const navigate = useNavigate()
  const { stationRecordNumber, woolDataTabValue } = useParams()

  // If root wool data route, redirect to wool contracts panel by default
  useEffect(() => {
    if (stationRecordNumber && woolDataTabValue === undefined) {
      navigate(`/stations/${stationRecordNumber}/wool-data/wool-contracts`)
    }
  }, [woolDataTabValue])

  return (
    <Stack data-testid="station-wool-data">
      <Tabs
        variant="pills"
        radius="md"
        value={woolDataTabValue}
        onChange={(value) => {
          navigate(`/stations/${stationRecordNumber}/wool-data/${value}`)
        }}
        orientation="vertical"
        keepMounted={false}
      >
        <Tabs.List mr="xl">
          <Tabs.Tab value="wool-contracts">Wool Contracts</Tabs.Tab>
          <Tabs.Tab value="lot-data">Lot Data</Tabs.Tab>
          <Tabs.Tab value="bale-details">Bale Details</Tabs.Tab>
          <Tabs.Tab value="creditor-history">Creditor History</Tabs.Tab>
          <Tabs.Tab value="receival-history">Receival History</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="wool-contracts">
          <WoolContracts />
        </Tabs.Panel>
        <Tabs.Panel value="lot-data">
          <LotDetails />
        </Tabs.Panel>
        <Tabs.Panel value="bale-details">
          <BaleDetails />
        </Tabs.Panel>
        <Tabs.Panel value="creditor-history">
          <CreditorHistory />
        </Tabs.Panel>
        <Tabs.Panel value="receival-history">
          <ReceivalHistory />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  )
}
